import type { UseFetchOptions } from "nuxt/app";
import { defu } from "defu";

export function useApiFetch<T>(url: string, options: UseFetchOptions<T> = {}) {
  const currentUrl = useRequestURL();

  const userAuth = useCookie("XSRF-TOKEN", {
    domain: `.${currentUrl.hostname}`,
  });

  const config = useRuntimeConfig();

  const defaults: UseFetchOptions<T> = {
    baseURL: config.public.apiBase,
    key: url,
    credentials: "include",
    headers: {
      "X-XSRF-TOKEN": userAuth.value as string,
      Accept: "application/json",
    },

    onResponse(_ctx) {
      // _ctx.response._data = new myBusinessResponse(_ctx.response._data)
    },

    onResponseError(_ctx) {
      // throw new myBusinessError()
    },
  };

  // for nice deep defaults, please use unjs/defu
  const params = defu(options, defaults);

  return useFetch(url, params);
}
